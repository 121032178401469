<template>
  <div>
    <div class="banner">
      <div class="container grid-banner">
        <div class="circle"></div>
        <div style="z-index:1">
          <div class="subtitle6 primary">플랫폼파이 테마</div>
          <div class="h6 main margin-top-16">간단한 편집이 가능한 디자인 빌더를 제공하는<br>플랫폼 서비스 테마</div>
          <div class="body2 sub2 margin-top-20">내 서비스에 맞는 테마를 선택하고<br>
            간단하게 디자인을 커스텀하여 플랫폼 서비스를 시작해보세요!</div>
          <button class="button is-primary-light margin-top-28"
                  style="width:160px;height:40px" @click="routerPush('/platformfy_info')">테마 주요기능 보기</button>
        </div>
        <video class="video" width="100%" :autoplay="true" :loop="true" muted
               controls="true" controlsList="nodownload" oncontextmenu="return false">
          <source src="https://s3.ap-northeast-2.amazonaws.com/launchpack-storage/media/non-path/service_main.mp4" type="video/mp4">
        </video>
      </div>
    </div>
    <div class="margin-top-60">
      <div class="container">
        <div class="box-error-50"><img src="/static/icon/ic_star11.svg"> 모든 테마를 <b>무료로 체험해보세요.</b></div>
        <div class="h7 main" style="margin:20px 0">플랫폼파이 테마</div>
        <div class="grid-theme">
          <a href="https://moaform.com/q/PEF75O" target="_blank" style="color:#ff6600">
            <div class="item-survey">
              <div class="size-24">어떤 테마를 선택해야할지<br>어려우신가요?</div>
              <div style="margin-top:12px;flex:1;">
                <img src="/static/icon/emoticon_hmm2.png" style="width:98px;height:60px">
              </div>
              <span class="subtitle4 unselect flex-align">
                내 서비스에 맞는 테마찾기
                <svg-icon style="height: 24px"
                          :width="18"
                          :height="18"
                          icon="u_arrow-up-right" color="primary"></svg-icon>
              </span>
            </div>
          </a>
          <card-product v-for="card in themes" :key="`card-${card.id}`" :card="card"></card-product>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from "../../../component/SvgIcon";
  import CardProduct from "../../../component/CardProduct";
  export default {
    name: "Themes",
    components: {CardProduct, SvgIcon},
    created() {
      this.getData();
    },
    data() {
      return {
        themes: []
      }
    },
    methods: {
      getData() {
        let params = {
          category: 59,
          fields: 'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate',
          page_num: 1,
          page_length: 10,
          ordering: 'priority'
        };
        this.$axios.get('user/0/mapping/product', {params: params}).then(res => {
          this.themes = res.data.data;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .banner
    background-color $gray4
    padding 40px 0
    overflow hidden
  .grid-banner
    position relative
    display grid
    gap 60px
    grid-template-columns 1fr 502px

  .circle
    position absolute
    width 358px
    height 358px
    left 823px
    bottom -123px
    background $primary
    opacity 0.9
    filter blur(600px)
    z-index 0

  .video
    z-index 1
    box-shadow 0 19px 20px 0 #FF660026

  .box-error-50
    display flex
    justify-content center
    align-items center
    background-color $error50
    color $error
    font-size 20px
    padding 12px 60px
    border-radius 8px
    img
      margin-right 12px
    b
      margin-left 4px

  .grid-theme
    display grid
    grid-column-gap 36px
    grid-row-gap 48px
    grid-template-columns repeat(3, 1fr)

  .item-survey
    background-color $primary-light2
    border-radius 8px
    height 100%
    display flex
    flex-direction column
    padding 32px
</style>
